import { default as _91name_937ySmE0oERsMeta } from "/app/pages/[slug]/[name].vue?macro=true";
import { default as indexq38bJr6bNoMeta } from "/app/pages/[slug]/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91name_936egIuftMDrMeta } from "/app/pages/phim/[name].vue?macro=true";
import { default as indexmsExruCBqwMeta } from "/app/pages/phim/index.vue?macro=true";
import { default as indexPhFEX78Fp4Meta } from "/app/pages/tim-kiem/index.vue?macro=true";
import { default as _91episodeName_93eoj8P3MMoxMeta } from "/app/pages/xem-phim/[name]/[episodeName].vue?macro=true";
import { default as component_45stubTOq3NWgWLHMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubTOq3NWgWLH } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: _91name_937ySmE0oERsMeta?.name ?? "slug-name",
    path: _91name_937ySmE0oERsMeta?.path ?? "/:slug()/:name()",
    meta: _91name_937ySmE0oERsMeta || {},
    alias: _91name_937ySmE0oERsMeta?.alias || [],
    redirect: _91name_937ySmE0oERsMeta?.redirect,
    component: () => import("/app/pages/[slug]/[name].vue").then(m => m.default || m)
  },
  {
    name: indexq38bJr6bNoMeta?.name ?? "slug",
    path: indexq38bJr6bNoMeta?.path ?? "/:slug()",
    meta: indexq38bJr6bNoMeta || {},
    alias: indexq38bJr6bNoMeta?.alias || [],
    redirect: indexq38bJr6bNoMeta?.redirect,
    component: () => import("/app/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_936egIuftMDrMeta?.name ?? "phim-name",
    path: _91name_936egIuftMDrMeta?.path ?? "/phim/:name()",
    meta: _91name_936egIuftMDrMeta || {},
    alias: _91name_936egIuftMDrMeta?.alias || [],
    redirect: _91name_936egIuftMDrMeta?.redirect,
    component: () => import("/app/pages/phim/[name].vue").then(m => m.default || m)
  },
  {
    name: indexmsExruCBqwMeta?.name ?? "phim",
    path: indexmsExruCBqwMeta?.path ?? "/phim",
    meta: indexmsExruCBqwMeta || {},
    alias: indexmsExruCBqwMeta?.alias || [],
    redirect: indexmsExruCBqwMeta?.redirect,
    component: () => import("/app/pages/phim/index.vue").then(m => m.default || m)
  },
  {
    name: indexPhFEX78Fp4Meta?.name ?? "tim-kiem",
    path: indexPhFEX78Fp4Meta?.path ?? "/tim-kiem",
    meta: indexPhFEX78Fp4Meta || {},
    alias: indexPhFEX78Fp4Meta?.alias || [],
    redirect: indexPhFEX78Fp4Meta?.redirect,
    component: () => import("/app/pages/tim-kiem/index.vue").then(m => m.default || m)
  },
  {
    name: _91episodeName_93eoj8P3MMoxMeta?.name ?? "xem-phim-name-episodeName",
    path: _91episodeName_93eoj8P3MMoxMeta?.path ?? "/xem-phim/:name()/:episodeName()",
    meta: _91episodeName_93eoj8P3MMoxMeta || {},
    alias: _91episodeName_93eoj8P3MMoxMeta?.alias || [],
    redirect: _91episodeName_93eoj8P3MMoxMeta?.redirect,
    component: () => import("/app/pages/xem-phim/[name]/[episodeName].vue").then(m => m.default || m)
  },
  {
    name: component_45stubTOq3NWgWLHMeta?.name ?? undefined,
    path: component_45stubTOq3NWgWLHMeta?.path ?? "/sitemap.xml",
    meta: component_45stubTOq3NWgWLHMeta || {},
    alias: component_45stubTOq3NWgWLHMeta?.alias || [],
    redirect: component_45stubTOq3NWgWLHMeta?.redirect,
    component: component_45stubTOq3NWgWLH
  }
]