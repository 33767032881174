import { type Base64ParseResult } from '@/utils/utils';
declare global {
  interface String {
    tryParseBase64(): Base64ParseResult;
    removeHtmlTags(): string;
  }
}
export function initPlugin() {
  String.prototype.tryParseBase64 = function () {
    const result = {
      value: null,
      isSuccessful: false,
    } as Base64ParseResult;
    try {
      if (typeof this !== 'string') {
        return theResultIs(false);
      }
      // Check for Base64 pattern
      const base64Pattern =
        /^(?:[A-Za-z0-9+\/]{4})*(?:[A-Za-z0-9+\/]{2}==|[A-Za-z0-9+\/]{3}=)?$/;
      if (!base64Pattern.test(this)) {
        return theResultIs(false);
      }
      // Attempt to decode
      let value = atob(this);
      return theResultIs(true, value);
    } catch (err) {
      return theResultIs(false);
    }

    function theResultIs(isSuccessful: boolean, value: string | null = null) {
      result.isSuccessful = isSuccessful;
      result.value = value;
      return result;
    }
  };

  String.prototype.removeHtmlTags = function () {
    let res = this.replace(/<[^>]*>/g, '');
    return res;
  };
}
