import { initPlugin } from './extension-methods.string';
// declare global {
//   interface String {
//     tryParseBase64(): Base64ParseResult;
//   }
// }

export default defineNuxtPlugin({
  _name: 'extensionMethod',
  enforce: 'pre',
  setup(nuxtApp) {
    initPlugin();
  },
});
