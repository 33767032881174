<template>
  <nuxt-loading-indicator />
  <nuxt-layout>
    <nuxt-page></nuxt-page>
  </nuxt-layout>
</template>
<script setup lang="ts">
import { inPagePush } from '@/ads/inPagePush';
import { pushNotification } from '@/ads/pushNotification.js';
import { vignetteBanner } from './ads/vignetteBanner';
import type { NavbarItem } from './entities/ui/header/navbar-item';
import { UseStateKey } from './enums/use-state-enum';

const adsScripts =
  process.env.NODE_ENV === 'production'
    ? [
        //multiple ads`
        // {
        //   src: 'https://alwingulla.com/88/tag.min.js',
        //   async: true,
        //   'data-zone': '75226',
        //   'data-cfasync': 'false',
        // },

        //in page push ads: Superior tag Anti-AdBlock
        {
          type: 'text/javascript',
          'data-cfasync': false,
          innerHTML: inPagePush.a,
        },
        {
          innerHTML: inPagePush.b,
        },
        // push notifications ads: Particular tag Anti-AdBlock
        {
          type: 'text/javascript',
          'data-cfasync': false,
          innerHTML: pushNotification.a,
        },
        {
          src: '//glimtors.net/ntfc.php?p=7662680',
          'data-cfasync': false,
          async: true,
          onerror: '_vtzskpaa()',
          onload: '_utuze()',
        },
        // native banner: Good Tag
        {
          src: '//thubanoa.com/1?z=7662717',
          async: true,
          'data-cfasync': false,
        },

        //Vignette Banner: Bright tag Anti-AdBlock
        {
          type: 'text/javascript',
          'data-cfasync': false,
          innerHTML: vignetteBanner.a,
        },
        {
          innerHTML: vignetteBanner.b,
        },

        {
          src: 'https://www.googletagmanager.com/gtag/js?id=G-GSYVTM10C1',
          async: true,
        },
        {
          innerHTML: `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-GSYVTM10C1');`,
        },
      ]
    : [];
useHead({
  link: [{ rel: 'icon', href: '/favicon.png', type: 'image/x-icon' }],
  script: [...adsScripts],
});
useSeoMeta({
  title:
    'ToPhimhay | Phim Hay | Phim HD Vietsub | Xem Phim Online | Xem Phim Nhanh | Phimmoi | Topphimhay | Top phim hay',
  description: `Xem phim online miễn phí chất lượng cao với phụ đề tiếng Việt - thuyết minh - lồng tiếng. Tophimhay có nhiều thể loại phim phong phú, đặc sắc, nhiều bộ phim hay nhất - mới nhất.`,
});
const navbarItems = useState(UseStateKey.NavbarItems);

await callOnce('appComponent', async () => {
  const { data, error, pending } = await useFetch<any>('/api/app-components', {
    cache: 'force-cache',
    key: 'appComponent',
  });
  navbarItems.value = (data.value?.navbarItems ?? []) as NavbarItem[];
});
</script>
