// Working code with Vue3

// Create Folder plugin > Create file useEmitter.js

import { defineNuxtPlugin } from '#app';
import mitt from 'mitt';
const emitter = mitt();

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('bus', emitter);
});
// Emit the event from a page to a component. Add below code inside setup function

// const { $bus } = useNuxtApp();
// $bus.$emit('clickEvent', { page: 'Demo Page' });
// // Component where you want to receive the event. Add below code inside setup function

// const { $bus } = useNuxtApp();

// $bus.$on('clickEvent', (data) => {
//   // do whatever you want with data
// });
