import $ from 'jquery';

declare global {
  interface Window {
    $: any;
    jQuery: any;
  }
}

export default defineNuxtPlugin({
  _name: 'jQuery',
  enforce: 'post',
  setup(nuxtApp) {
    // nuxtApp.provide('$', $);
    window.$ = window.jQuery = $;
  },
});
